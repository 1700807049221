import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import './Home.css';

function Home() {
  const [activeSection, setActiveSection] = useState(null);
  const contentContainerRef = useRef(null);

  const handleScroll = () => {
    const contentSections = ['introduction', 'authentication', 'product-documentation', 'recommendation-documentation'];

    const scrollTop = contentContainerRef.current.scrollTop;
    const windowHeight = contentContainerRef.current.clientHeight;
    const scrollBottom = scrollTop + windowHeight;

    let found = false;

    for (const section of contentSections) {
      const sectionElement = document.getElementById(section);
      if (!sectionElement) continue;

      const sectionTop = sectionElement.offsetTop;
      const sectionBottom = sectionTop + sectionElement.offsetHeight;

      if (scrollTop >= sectionTop && scrollBottom <= sectionBottom) {
        setActiveSection(section);
        found = true;
        break;
      }
    }

    if (!found) {
      setActiveSection(null);
    }
  };

  useEffect(() => {
    const containerRef = contentContainerRef.current;
    containerRef.addEventListener('scroll', handleScroll);
    return () => {
      containerRef.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
    }
  };

  const redirectToProductDocumentation = () => {
    window.location.href = 'https://developer.sizey.ai/product';
  };

  const redirectToRecommendationDocumentation = () => {
    window.location.href = 'https://developer.sizey.ai/recommendation';
  };

  return (
    <div className='body-container'>
      <div className='nav-container'>
        <div className='company-logo'>
        <a href="https://developer.sizey.ai">
          <img className='image-logo' alt='Sizey Logo' src="/sizey.png"></img>
        </a>
        </div>
        <hr></hr>
        <div className='content-navbar'>
          <ul className='list-component' role='navigation'>
            <li
              className={`component-name ${activeSection === 'introduction' ? 'active' : ''}`}
              onClick={() => scrollToSection('introduction')}
            >
              Introduction
            </li>
            <li
              className={`component-name ${activeSection === 'authentication' ? 'active' : ''}`}
              onClick={() => scrollToSection('authentication')}
            >
              Authentication
            </li>
            <li
              className={`component-name ${activeSection === 'product-documentation' ? 'active' : ''}`}
              onClick={redirectToProductDocumentation}
            >
              Product Documentation
            </li>
            <li
              className={`component-name ${activeSection === 'recommendation-documentation' ? 'active' : ''}`}
              onClick={redirectToRecommendationDocumentation}
            >
              Recommendation Documentation
            </li>
          </ul>
        </div>
      </div>
      <div className='content-container' ref={contentContainerRef}>
        <div id="introduction">
          <p className='heading-text'>Introduction</p>
          <p className='heading-content'>This is the technical documentation for Sizey services for apparel fit API. This documentation is intended for technical persons working with the APIs. </p>
        </div>
        <div id="authentication">
          <p className='heading-text'>Authentication</p>
          <div className='authentication-block'>
            <div className='auth-heading'>
              <p>x-sizey-key</p>
              <div>
                <table className='table-block'>
                  <tbody className='table-body'>
                    <tr className='table-row'>
                      <th className='table-heading'> Security Scheme Type </th>
                      <td className='table-data'> API Key </td>
                    </tr>
                    <tr className='table-row'>
                      <th className='table-heading'> Header paameter name: </th>
                      <td className='table-data'> APIKEY </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='authentication-block'>
            <div className='auth-heading'>
              <p>BearerToken</p>
              <div>
                <table className='table-block'>
                  <tbody className='table-body'>
                    <tr className='table-row'>
                      <th className='table-heading'> Security Scheme Type </th>
                      <td className='table-data'> HTTP </td>
                    </tr>
                    <tr className='table-row'>
                      <th className='table-heading'> HTTP Authorization Scheme </th>
                      <td className='table-data'> bearer </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Home;