import React from 'react';
import {
  RedocStandalone
} from 'redoc';
import './App.css';


function Api(params) {
  const { spec } = params;
  return ( <
    RedocStandalone options = {
      {
        hideDownloadButton: false
      }
    }
    specUrl = {spec} / > );
}

export default Api;
