import React, { useState, useRef, useEffect } from 'react';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import './App.css';

function Measurement(props) {
  const { title, value, onChange } = props;
  return <div>{title} <input type="number" value={value || 0} onChange={e => onChange(e.target.value)} /></div>
}

function Measurements(props) {
  const { onChange } = props;
  const [measurements, setMeasurements] = useState('');

  useEffect(() => {
    setMeasurements(props.measurements);
  }, [props]);

  var updateMeasurements = m => {
    setMeasurements(m);
    onChange(m);
  }

  var clearMeasurements = () => {
    Object.keys(measurements.circumference).forEach(p => measurements.circumference[p] = 0);
    Object.keys(measurements.length).forEach(p => measurements.length[p] = 0);
    Object.keys(measurements.width).forEach(p => measurements.width[p] = 0);

    console.log(measurements);
    updateMeasurements({...measurements});
  }

  return measurements ? <div>
    {Object.keys(measurements.circumference).map(c => {
      return <Measurement key={`circ-${c}`} title={`Circumference of ${c}`} value={measurements.circumference[c]} onChange={val => { measurements.circumference[c] = +val; updateMeasurements({ ...measurements }); }}></Measurement>
    })}
    {Object.keys(measurements.length).map(c => {
      return <Measurement key={`length-${c}`} title={`Length of ${c}`} value={measurements.length[c]} onChange={val => { measurements.length[c] = +val; updateMeasurements({ ...measurements }); }}></Measurement>
    })}
    {Object.keys(measurements.width).map(c => {
      return <Measurement key={`width-${c}`} title={`Width of ${c}`} value={measurements.width[c]} onChange={val => { measurements.width[c] = +val; updateMeasurements({ ...measurements }); }}></Measurement>
    })}
    <button onClick={e => {e.preventDefault(); clearMeasurements();}}>Clear</button>
  </div> : <div>No measurements</div>
}

function Image(props) {
  const fileUploader = useRef(null);
  const [image, setImage] = useState('');

  var handleSubmit = function (e) {
    e.preventDefault();
    fileUploader.current.click();
  }

  var handleImageChange = function (e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage(reader.result);
      props.onImage(reader.result);
    }

    reader.readAsDataURL(file)
  }

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <input className="fileInput" ref={fileUploader} style={{ display: 'none' }}
          type="file"
          onChange={(e) => handleImageChange(e)} />
        <button className="submitButton"
          type="submit"
          onClick={(e) => handleSubmit(e)}>{props.title}</button>
      </form>
      {image && <img alt="" src={image} />}
    </div>
  );
}

function Home() {
  const [sideImage, setSideImage] = useState('');
  const [frontImage, setFrontImage] = useState('');
  const [measurements, setMeasurements] = useState('');
  const [recommendation, setRecommendation] = useState('');

  var measure = function (side, front) {

    from(fetch("https://measurement-api.sizey.ai/api/measure", {
      headers: {
        'x-sizey-key': "bm9taTpYUXlTWkxFcHRW",
        'Content-Type': 'application/json'

      },
      method: 'POST',
      body: JSON.stringify({
        gender: 'male',
        front: side.replace('data:image/png;base64,', ''),
        side: front.replace('data:image/png;base64,', ''),
        height: 1800
      })
    })).pipe(switchMap(resp => from(resp.json()))).subscribe(resp => {
      setMeasurements(resp);
    })
  }

  var recommend = function (measurements) {

    from(fetch("https://recommendation-api.sizey.ai/recommendation", {
      headers: {
        'x-sizey-key': "bm9taTpYUXlTWkxFcHRW",
        'Content-Type': 'application/json'
      },
      method: 'POST',
      type: '',
      body: JSON.stringify({
        bodyMeasurements: measurements,
        sizeChart: {
          brand: 'sizey',
          gender: 'female'
        }
      })
    })).pipe(switchMap(resp => from(resp.json()))).subscribe(resp => {
      setRecommendation(resp);
    })
  }

  var openPopup = function (measureOnly, measurements) {

    const url = `https://recommendation.sizey.ai/?apikey=bm9taTpYUXlTWkxFcHRW&brand=hm&gender=men&storeName=Jartsan&clothName&itemName=Housut` + (measureOnly ? "&measureOnly" : "") + (measurements ? `&measurements=${btoa(JSON.stringify(measurements))}` : '');
    window.open(url, 'popup', 'width=800,height=800,scrollbars=yes,resizable=yes,toolbar=no,titlebar=no,menubar=no,location=no,status=no');

    window.onmessage = function (e) {
      const sizeyEvent = e.data;
      if (sizeyEvent.event === 'sizey-recommendation') {
        const recommendation = sizeyEvent.recommendation;
        setRecommendation(recommendation);
      }
      if (sizeyEvent.event === 'sizey-measurements') {
        const measurements = sizeyEvent.measurements;
        setMeasurements(measurements);
      }
    };
  }

  if (!measurements) {
    var measurementsString = '{"circumference":{"ankle":393.50516550529574,"biceps":342.60591220574133,"calf":407.10930116857344,"chest":1028.8315369295888,"elbow":290.3007380946183,"forearm":262.03265380859375,"head":611.9697265625,"hip":1009.9733755723719,"knee":587.4053262554808,"neck":378.06207275390625,"thigh":575.7732543945312,"trouserWaist":917.7861608905005,"underBust":null,"waist":899.2257159282744,"wrist":187.90107242820523},"length":{"arm":581.5000915527344,"foot":265.5203857421875,"hand":198.58609008789062,"head":248.29696655273438,"hip":188.83502197265625,"insideLeg":820.1536254882812,"leg":1087.4994506835938,"lowerArm":269.5395812988281,"lowerLeg":403.2965393066406,"neck":49.45842742919922,"torso":728.8529052734375,"upperArm":311.96051025390625,"upperBody":540.0178833007812,"upperLeg":418.6825256347656},"width":{"shoulder":445.1141662597656},"gender":"male","height":1900,"mesh":"eyJtZXNoX3ZlcnNpb24iOiAxLCAidmFsdWVzIjogWzYxMS45Njk3MjY1NjI1LCAzNzguMDYyMDcyNzUzOTA2MjUsIDk4MS4wMTUxMzY3MTg3NSwgODcxLjY2OTg2MDgzOTg0MzgsIDgzMi4xMzY2NTc3MTQ4NDM4LCA5MzguMjczOTg2ODE2NDA2MiwgOTgzLjk1NzY0MTYwMTU2MjUsIDU3NS43NzMyNTQzOTQ1MzEyLCAzNjIuMTU2MDM2Mzc2OTUzMSwgMzYyLjA4MzM3NDAyMzQzNzUsIDIyNi45NTQ2MDUxMDI1MzkwNiwgMjgwLjI0NjczNDYxOTE0MDYsIDI1MS41NjI3NTkzOTk0MTQwNiwgMjYyLjAzMjY1MzgwODU5Mzc1LCAxNjguMjMxMjc3NDY1ODIwMywgMjQ4LjI5Njk2NjU1MjczNDM4LCA0OS40NTg0Mjc0MjkxOTkyMiwgNDQ1LjExNDE2NjI1OTc2NTYsIDU0MC4wMTc4ODMzMDA3ODEyLCAxODguODM1MDIxOTcyNjU2MjUsIDQxOC42ODI1MjU2MzQ3NjU2LCA0MDMuMjk2NTM5MzA2NjQwNiwgMzExLjk2MDUxMDI1MzkwNjI1LCAyNjkuNTM5NTgxMjk4ODI4MSwgMTk4LjU4NjA5MDA4Nzg5MDYyLCAyNjUuNTIwMzg1NzQyMTg3NSwgODIwLjE1MzYyNTQ4ODI4MTJdLCAiZ2VuZGVyIjogIm1hbGUifQ==","timestamp":"2020-04-11T16:46:16.020Z"}';
    setMeasurements(JSON.parse(measurementsString))
  }

  return (
    <div className="container">
      <h1>Hola amigos!</h1>

      <button onClick={e => openPopup(true)}>Open popup, measure only</button>
      {measurements && <button onClick={e => openPopup(false, measurements)}>Get recommendation (use existing measurement)</button>}

      <Image title="Front image" onImage={img => setFrontImage(img)}></Image>
      {frontImage && <Image title="Side image" onImage={img => setSideImage(img)}></Image>}

      {sideImage && frontImage && <button onClick={e => measure(sideImage, frontImage)}>Measure me!</button>}
      {measurements && <Measurements measurements={measurements} onChange={m => setMeasurements(m)}></Measurements>}

      {measurements && <button onClick={e => recommend(measurements)}>Give me recommendation!</button>}

      {recommendation && <pre>{JSON.stringify(recommendation, null, 2) }</pre>}

    </div>
  );
}

export default Home;