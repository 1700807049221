import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

function ImportProductsSample() {
    const [downloaded, setDownloaded] = useState(false)
    const navigate = useNavigate();

    async function downloadSampleExcel(req, res) {
        const dummyData = [
            {
                'Product EAN': '123456789',
                'Brand Name': 'Dummy Brand',
                'Product Name': 'Dummy Product',
                'Size chart ID': 'S5I5yDNaOTYKgyJBxA9Q',
                'Product type': 'Dummy Type',
                'General description field': 'This is a dummy product.',
                'Variation EAN Code': 'V123456789',
                'Size': 'L',
                'Colour': 'Red',
                'Fabric': 'Cotton'
              },
        ];
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(dummyData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = await XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
      
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'dummy_exported_data.xlsx';
        link.click();

        URL.revokeObjectURL(url);
        setDownloaded(true)
      }
    useEffect(() => {
        if(downloaded){
            navigate(-1);
        } else {
            downloadSampleExcel();
        }
    }, [downloaded, navigate]);

  return (
    <></>
  );
}

export default ImportProductsSample;
